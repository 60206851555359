document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('[data-toggle-menu]').forEach(el => {
        el.addEventListener('click', function (e) {
            e.preventDefault();
            this.querySelector('.header__burger-switcher').classList.toggle('is-open');


            if(this.querySelector('.header__burger-switcher').classList.contains('is-open')){
                document.querySelector('body').classList.add('popup-lock');
                document.querySelector('.menu-adaptive').classList.add('open');
            } else {
                document.querySelector('body').classList.remove('popup-lock');
                document.querySelector('.menu-adaptive').classList.remove('open');
            }



            if(window.innerWidth >= 960){
                $('.menu-adaptive__wrapper > .menu-adaptive__item:first-child').addClass('active');
                $('.menu-adaptive__wrapper > .menu-adaptive__item:first-child').siblings().removeClass('active');
            } else{
                $('.menu-adaptive__wrapper > .menu-adaptive__item').removeClass('active');
            }

        });
    });


    $('.menu-adaptive__wrapper > .menu-adaptive__item > .menu-adaptive__name').on('mouseenter', function (e) {
        if(window.innerWidth >= 960){
            $(this).parent().addClass('active');
            $(this).parent().siblings().removeClass('active');
        }
    });

    $('.menu-adaptive__name').on('click', function (e) {


        if(window.innerWidth < 960){
            //если есть чему раскрыться - раскрываемся

            if($(this).siblings('ul').length){
                e.preventDefault();

                $(this).parent().toggleClass('active');
                $(this).parent().siblings().removeClass('active');
            }
        }
    });


    setMenuPositionTop();
    headerFixed();

    $(window).scroll(function (event) {
        headerFixed();
    });


    // Фиксация шапки
    function headerFixed() {
        let st = $(window).scrollTop();
        // if (st > (($('.shop-link').outerHeight() ? $('.shop-link').outerHeight() : 0) + $('.header-top').outerHeight())) {
        if (st > 90) {
            $('header').addClass('fixed');
            $('.header').addClass('fixed');
            // $('.main-wrapper').addClass('fixed');
            setMenuPositionTop();
        } else if (st < 50) {
            $('header').removeClass('fixed');
            $('.header').removeClass('fixed');
            setMenuPositionTop();
        }
    }

    // Позиционирование выпадающего меню каталога
    function setMenuPositionTop() {
        setTimeout(function () {
            let windowHeight = $(window).outerHeight();
            let header = $('header').outerHeight();
            let headerMiddle = $('.header').outerHeight();
            let headerMenu = $('.header-menu').outerHeight();
            let headerTop = $('.header-top').outerHeight();
            let shopLink = ($('.shop-link').outerHeight() ? $('.shop-link').outerHeight() : 0);

            $('header').css({
                top: '-' + (shopLink + headerTop) + 'px'
            });
            if ($('header').hasClass('fixed')) {

                $('.header-menu-catalog').css({
                    height: (windowHeight - (headerMiddle + headerMenu)) + 'px',
                    top: (headerMiddle + headerMenu) + 'px'
                });
            } else {
                // $('header').css({
                //     top: '0px'
                // });
                $('.header-menu-catalog').css({
                    height: (windowHeight - (header)) + 'px',
                    top: header + 'px'
                });
            }
        }, 500);
    }

});